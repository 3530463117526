import axios from 'axios';
import {get} from 'lodash-es';
import {apiDomain} from '@/constants';

export default function extendAxios(store, router) {
  const createSetAuthInterceptor = () => (rawConfig) => {
    const config = {
      ...rawConfig,
      url: rawConfig.url.replace(/^{apiDomain}/, apiDomain),
    };

    const accessToken = get(store, 'state.auth.accessToken');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  };
  const setAuthCb = createSetAuthInterceptor();
  axios.interceptors.request.use(setAuthCb);

  let refreshTokenPromise;

  function handleTokenError(error) {
    store.state.error = error.response.data;
    if (get(router.currentRoute, 'meta.requirements.authorized')) {
      router.push({name: 'login'});
    }
    return Promise.reject(error);
  }

  const createUpdateAuthInterceptor = http => async (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    if (!get(store, 'state.auth.refreshToken')) {
      store.dispatch('auth/clearUserData');
      return handleTokenError(error);
    }
    if (!refreshTokenPromise) {
      refreshTokenPromise = store.dispatch('auth/refreshToken');
    }
    try {
      await refreshTokenPromise;
      refreshTokenPromise = null;
      return http(error.config);
    } catch {
      refreshTokenPromise = null;
      return handleTokenError(error);
    }
  };
  const updateAuthCb = createUpdateAuthInterceptor(axios);
  axios.interceptors.response.use(null, updateAuthCb);
}
