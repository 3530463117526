export default function convertDateString(date) {
  const enMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const ruMonths = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']

  const elements = date.split(' ')
  const idx = ruMonths.indexOf(elements[1])

  if (idx === -1) {
    console.error('Invalid date format: ' + date)
  } else {
    if (elements.length === 4) {
      elements[2] += ' ' + elements[3]
    }

    return new Date(elements[0] + ' ' + enMonths[idx] + ' ' + elements[2])
  }

  return null
}
