export default {
  bind(el) {
  },
  inserted(el, {value}) {
    initTimePicker(el, value)
  },
  componentUpdated(el, {value}) {
    initTimePicker(el, value)
  },
  unbind(el) {
    const instance = M.Timepicker.getInstance(el);

    if (instance && instance.destroy) {
      instance.destroy()
    }
  }
}

function initTimePicker(el, value) {
  const instance = M.Timepicker.getInstance(el);

  if (!instance) {
    M.Timepicker.init(el, {
      showClearBtn: false,
      autoClose: false,
      twelveHour: false,
      defaultTime: '00:00',
      i18n: {
        // Today and clear
        clear: 'Очистить',
        cancel: 'Отменить',
        done: 'Ок',
      },
    });
  } else if (value) {
    // TODO: распарсить значение и подставить в форму, аналогично датапикеру
    // getOvertimeForWidget(selectedWorkingShift.overtime1)
    console.log(value)
  }
}
