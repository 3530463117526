import axios from "axios";

export default {
  actions: {
    async fetchObjectMacroRegions({commit, dispatch}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/macro_regions');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchObjectMacroRegionById({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/macro_regions/' + id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async updateObjectMacroRegion({commit, dispatch}, macroRegion) {
      try {
        const response = await axios.put('{apiDomain}/crm/api/v1/macro_regions/' + macroRegion.id, macroRegion);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async createObjectMacroRegion({commit, dispatch}, macroRegion) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/macro_regions', macroRegion);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    }
  }
}
