import axios from "axios";

export default {
  actions: {
    async fetchObjectClusters({commit, dispatch}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/clusters');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchObjectClusterById({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/clusters/' + id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async updateObjectCluster({commit, dispatch}, cluster) {
      try {
        const response = await axios.put('{apiDomain}/crm/api/v1/clusters/' + cluster.id, cluster);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async createObjectCluster({commit, dispatch}, cluster) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/clusters', cluster);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    }
  }
}
