export default {
  logout: 'Выход',
  login: 'Пожалуйста авторизуйтесь',
  'cmn000001': 'Неизвестная ошибка, попробуй выполнить запрос позже',
  'cmn000002': 'Ошибка параметров запроса',
  'cmn000003': 'Ошибка валидации полей запроса',
  'cmn000004': 'Не удалось отправить сообщение в телеграм',
  'cmn000005': 'Не удалось отправить сообщение на почту',
  'auth000001': 'Не удалось создать пользователя',
  'auth000002': 'Не удалось получить информацию о пользователе',
  'auth000003': 'Не удалось обновить пользователя',
  'auth000004': 'Не удалось авторизовать пользователя',
  'auth000005': 'Не удалось обновить токен',
  'auth000007': 'Не удалось осуществить выход',
  'auth000008': 'Не удалось инициировать восстановление пароля',
  'core000001': 'Не удалось получить бригаду',
  'core000002': 'Не удалось получить заявку',
  'core000003': 'Не удалось получить отчет о заявке',
  'core000004': 'Не удалось добавить отчет о заявке',
  'core000005': 'Не удалось изменить статус заявки',
  'core000006': 'Не удалось загрузить фотографию для отчета',
}
