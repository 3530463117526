import convertDateString from '../utils/date'

export default {
  bind(el) {
  },
  inserted(el, {value}) {
    initDatePicker(el, value)
  },
  componentUpdated(el, {value}) {
    initDatePicker(el, value)
  },
  unbind(el) {
    const instance = M.Datepicker.getInstance(el);

    if (instance && instance.destroy) {
      instance.destroy()
    }
  }
}

function initDatePicker(el, value) {
  const instance = M.Datepicker.getInstance(el);

  if (!instance) {
    M.Datepicker.init(el, {
      format: 'd mmmm yyyy',
      firstDay: 1,
      showDaysInNextAndPreviousMonths: true,
      showClearBtn: true,
      autoClose: true,
      i18n: {
        // Months and weekdays
        months: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
        monthsShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        weekdaysShort: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],

        // Materialize modified
        weekdaysAbbrev: ['В', 'П', 'В', 'С', 'Ч', 'П', 'С'],
        format: 'd mmmm yyyy',

        // Today and clear
        today: 'Сегодня',
        clear: 'Очистить',
        cancel: 'Отменить',
        close: 'Ок',
      },
    });
  } else if (value) {
    const date = convertDateString(value)

    if (date) {
      instance.setDate(date)
    }
  }
}
