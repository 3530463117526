import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import objects from './objects'
import customers from './customers'
import home_locations from './home_locations'
import tasks from './tasks'
import teams from './teams'
import users from './users'
import projects from './projects'
import equipment_types from './equipment_types'
import object_clusters from './object_clusters'
import object_macro_regions from './object_macro_regions'
import departments from './departments'
import profile from './profile'
import working_shift from './working_shift'
//import record from './record'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null
  },
  mutations: {
    setError(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = null
    }
  },
  actions: {
    async initState({ dispatch }) {
      try {
        await dispatch('auth/initState');
      } catch (error) {
      }
    },
    async logout({ dispatch }) {
      try {
        await dispatch('auth/logout');
      } catch (error) {
      }
    }
  },
  getters: {
    error: s => s.error
  },
  modules: {
    auth, profile, objects, customers, home_locations, tasks, teams, users, projects,
    equipment_types, object_clusters, object_macro_regions, departments, working_shift,
  }
})
