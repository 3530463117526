import Vue from 'vue';
import Router from 'vue-router';
import {once} from 'lodash-es';
import store from './store/index';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: {layout: 'empty'},
      component: () => import('./views/Login')
    },
    {
      path: '/',
      name: 'home',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/Home')
    },
    {
      path: '/objects',
      name: 'objects',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/objects/Objects')
    },
    {
      path: '/objects/add',
      name: 'addObject',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/objects/AddObject')
    },
    {
      path: '/objects/:id',
      name: 'editObject',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/objects/EditObject')
    },
    {
      path: '/users',
      name: 'users',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/users/Users')
    },
    {
      path: '/users/:id',
      name: 'editUser',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/users/EditUser')
    },
    {
      path: '/teams',
      name: 'teams',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/teams/Teams')
    },
    {
      path: '/teams/add',
      name: 'teamAdd',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/teams/AddTeam')
    },
    {
      path: '/teams/:id',
      name: 'teamEdit',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/teams/EditTeam')
    },
    {
      path: '/tasks',
      name: 'tasks',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/tasks/Tasks')
    },
    {
      path: '/tasks/add',
      name: 'addTask',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/tasks/AddTask')
    },
    {
      path: '/tasks/import',
      name: 'importTasks',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/tasks/ImportTasks')
    },
    {
      path: '/tasks/:id/report',
      name: 'reportTask',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/tasks/Report')
    },
    {
      path: '/tasks/:id',
      name: 'editTask',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/tasks/EditTask')
    },
    {
      path: '/home_locations',
      name: 'homeLocations',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/homeLocations/HomeLocations')
    },
    {
      path: '/home_locations/add',
      name: 'addHomeLocation',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/homeLocations/AddHomeLocation')
    },
    {
      path: '/home_locations/:id',
      name: 'editHomeLocation',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/homeLocations/EditHomeLocation')
    },
    {
      path: '/customers',
      name: 'customers',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/customers/Customers')
    },
    {
      path: '/customers/add',
      name: 'addCustomer',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/customers/AddCustomer')
    },
    {
      path: '/customers/:id',
      name: 'editCustomer',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/customers/EditCustomer')
    },
    {
      path: '/equipment_types',
      name: 'equipmentTypes',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/equipmentTypes/EquipmentTypes')
    },
    {
      path: '/equipment_types/add',
      name: 'addEquipmentType',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/equipmentTypes/AddEquipmentType')
    },
    {
      path: '/equipment_types/:id',
      name: 'editEquipmentType',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/equipmentTypes/EditEquipmentType')
    },
    {
      path: '/clusters',
      name: 'objectClusters',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/objectClusters/ObjectClusters')
    },
    {
      path: '/clusters/add',
      name: 'addObjectCluster',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/objectClusters/AddObjectCluster')
    },
    {
      path: '/clusters/:id',
      name: 'editObjectCluster',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/objectClusters/EditObjectCluster')
    },
    {
      path: '/macro_regions',
      name: 'objectMacroRegion',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/macroRegions/MacroRegions')
    },
    {
      path: '/macro_regions/add',
      name: 'addMacroRegion',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/macroRegions/AddMacroRegion')
    },
    {
      path: '/macro_regions/:id',
      name: 'editMacroRegion',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/macroRegions/EditMacroRegion')
    },
    {
      path: '/projects',
      name: 'projects',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/projects/Projects')
    },
    {
      path: '/projects/add',
      name: 'addProject',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/projects/AddProject')
    },
    {
      path: '/projects/:id',
      name: 'editProject',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/projects/EditProject')
    },
    {
      path: '/maps',
      name: 'maps',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/Map')
    },
    {
      path: '/working_shifts',
      name: 'working_shifts',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/WorkingShifts')
    },
/*
    {
      path: '/detail/:id',
      name: 'detail',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/Detail.vue')
    },
    {
      path: '/history',
      name: 'history',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/History.vue')
    },
    {
      path: '/planning',
      name: 'planning',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/Planning.vue')
    },
    {
      path: '/record',
      name: 'record',
      meta: {layout: 'main', auth: true},
      component: () => import('./views/Record.vue')
    }*/
  ]
})

const initRootStore = once(async () => {
  await store.dispatch('initState');
});

router.beforeEach(async (to, from, next) => {
  try {
    await initRootStore();
  } catch (error) {
    return undefined;
  }

  const currentUser = store.state.profile.user
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && !currentUser) {
    next('/login?message=login')
  } else {
    next()
  }
});


export default router;
