import axios from "axios";

export default {
  actions: {
    async getRoles({dispatch, commit}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/roles');
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchUsers({commit, dispatch}, {departmentId, teamId, role, search, page, size}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/users', {
          params: {
            department_id: departmentId,
            team_id: teamId,
            role: role,
            search: search,
            page: page,
            size: size,
          }
        });
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchDispatchers({commit}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/users', {
          params: {
            role: 'dispatcher',
          }
        });
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchUsersForTeam({commit, dispatch}, departmentId) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/teams/users', {
          params: {
            department_id: departmentId,
          }
        });
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchUserById({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/users/' + id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async updateUser({commit, dispatch}, user) {
      try {
        const response = await axios.put('{apiDomain}/crm/api/v1/users/' + user.user.id, user);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async deleteUser({commit, dispatch}, user_id) {
      try {
        const response = await axios.delete('{apiDomain}/crm/api/v1/users/' + user_id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async activateUser({commit, dispatch}, user_id) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/users/' + user_id + '/activate');
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async resetPassword({commit, dispatch}, user_id) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/users/' + user_id + '/reset_password');
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    refreshTracking({commit, dispatch}, user_id) {
      try {
        const response = axios.post('{apiDomain}/crm/api/v1/user/' + user_id + '/tracking/refresh');
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
  }
}
