import axios from "axios";

export default {
  actions: {
    async fetchProjects({commit, dispatch}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/projects');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchProjectById({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/projects/' + id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async updateProject({commit, dispatch}, project) {
      try {
        const response = await axios.put('{apiDomain}/crm/api/v1/projects/' + project.id, project);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async createProject({commit, dispatch}, project) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/projects', project);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    }
  }
}
