<template>
  <nav class="navbar blue darken-4">
    <div class="nav-wrapper">
      <div class="navbar-left">
        <a href="#" @click.prevent="$emit('click')">
          <i class="material-icons white-text">dehaze</i>
        </a>
        <span class="white-text hide-on-small-and-down">{{ date | date('datetime') }}</span>
      </div>

      <ul class="right">
        <li>
          <a class="dropdown-trigger white-text" href="#" data-target="dropdown" ref="dropdown">
            {{ name }} ({{ role }})
            <i class="material-icons right">arrow_drop_down</i>
          </a>

          <ul id="dropdown" class="dropdown-content">
            <li>
              <a href="#" class="black-text" @click.prevent="profile">
                <i class="material-icons">account_circle</i>
                Профиль
              </a>
            </li>
            <li class="divider" tabindex="-1"></li>
            <li>
              <a href="#" class="black-text" @click.prevent="logout">
                <i class="material-icons">assignment_return</i>
                Выход
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script>
export default {
  data: () => ({
    date: new Date(),
    interval: null,
    dropdown: null,
    profileLink: '',
  }),
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
      this.$router.push('/login?message=logout')
    },
    async profile() {
      const uid = await this.$store.dispatch('profile/getUid')
      const path = '/users/' + uid

      if (path === this.$router.currentRoute.path) {
        this.$router.go(0)
      } else {
        await this.$router.push(path)
      }
    },
  },
  computed: {
    name() {
      return this.$store.state.profile.user != null ? this.$store.state.profile.user.name : ''
    },
    role() {
      if (this.$store.state.profile.user == null) {
        return 'нет'
      }

      switch (this.$store.state.profile.user.role) {
        case "owner":
          return 'владелец';
        case 'admin':
          return 'администратор';
        case 'dispatcher':
          return 'диспетчер';
        case 'performer':
          return 'исполнитель';
        default:
          return 'нет';
      }
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.date = new Date()
    }, 1000)
    this.dropdown = M.Dropdown.init(this.$refs.dropdown, {
      constrainWidth: false
    })
  },
  beforeDestroy() {
    clearInterval(this.interval)
    if (this.dropdown && this.dropdown.destroy) {
      this.dropdown.destroy()
    }
  }
}
</script>
