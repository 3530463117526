export default function locationLinkFilter(location, zoom) {
  if (!location) {
    return ''
  }

  const z = zoom || 15

  return 'https://www.openstreetmap.org/' +
    '?mlat=' + location.lat +
    '&mlon=' + location.lng +
    '#map=' + z + '/' + location.lat + '/' + location.lng
}
