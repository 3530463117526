import axios from 'axios';

export default {
  state: {
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    isAuthorised: false,
  },

  mutations: {
    accessToken(state, value) {
      state.accessToken = value;
    },
    refreshToken(state, value) {
      state.refreshToken = value;
    },
    isAuthorised(state, value) {
      state.isAuthorised = value;
    },
  },

  actions: {
    async initState({state, commit, dispatch}) {
      if (!state.accessToken && !state.refreshToken) {
        return;
      }

      let user = await dispatch('profile/initUserData', null, {root: true});
      if (user.User) {
        commit('isAuthorised', true);
      }
    },

    async login({state, commit, dispatch}, {email, password, rememberMe}) {
      const {data} = await axios.post('{apiDomain}/auth/api/v1/login', {
        email,
        password,
        remember_me: rememberMe,
      });
      await dispatch('initAuthedUserData', data);
      await dispatch('profile/initUserData', null, {root: true});
    },

    initAuthedUserData({commit}, data) {
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      commit('accessToken', data.access_token);
      commit('refreshToken', data.refresh_token);
      commit('isAuthorised', true);
    },

    clearUserData({commit, dispatch}) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      commit('accessToken', '');
      commit('refreshToken', '');
      commit('isAuthorised', false);
      commit('profile/user', null, {root: true});
    },

    /**
     * Refresh expired token
     * @param state
     * @param commit
     * @param dispatch
     * @returns {Promise.<T>|Promise<any>|Promise}
     */
    async refreshToken({state, commit, dispatch}) {
      try {
        const {data} = await axios.post('{apiDomain}/auth/api/v1/refresh_token', {
          refresh_token: state.refreshToken,
        });
        await dispatch('initAuthedUserData', data);
      } catch (error) {
        dispatch('clearUserData');
        commit('setError', error);
        throw error;
      }
    },

    async logout({commit, dispatch}) {
      try {
        await axios.get('{apiDomain}/auth/api/v1/user/logout');
        dispatch('clearUserData');
      } catch (error) {
        dispatch('clearUserData');
        commit('setError', error);
        throw error;
      }
    },
  },

  namespaced: true,
}
