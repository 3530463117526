import axios from "axios";

export default {
  actions: {
    async fetchCustomers({commit, dispatch}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/customers');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchCustomersAssociation({commit, dispatch}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/customers/association');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchCustomerById({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/customers/' + id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async updateCustomer({commit, dispatch}, customer) {
      try {
        const response = await axios.put('{apiDomain}/crm/api/v1/customers/' + customer.id, customer);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async createCustomer({commit, dispatch}, customer) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/customers', customer);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async findAssociation({commit, dispatch}, input) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/customers/association-find', {input: input});
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
  }
}
