import axios from "axios";

export default {
  actions: {
    async fetchDepartments({dispatch, commit}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/departments');
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
  },
}
