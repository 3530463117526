import axios from "axios";

export default {
  actions: {
    async fetchObjects({commit, dispatch}, {search, page, size}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/objects', {
          params: {
            search: search,
            page: page,
            size: size,
          }
        });
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async findObject({commit, dispatch}, input, limit) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/objects/find', {input: input, limit: limit});
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchObjectById({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/objects/' + id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async updateObject({commit, dispatch}, object) {
      try {
        const response = await axios.put('{apiDomain}/crm/api/v1/objects/' + object.id, object);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async createObject({commit, dispatch}, object) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/objects', object);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    }
  }
}
