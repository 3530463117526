import axios from "axios";
import convertDateString from "@/utils/date";

export default {
  actions: {
    async fetchStatuses({commit, dispatch}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/tasks/statuses');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchTypes({commit, dispatch}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/tasks/types');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchTasks({commit, dispatch}, {department_id, engineer_id, team_id, object, status, type, planned_end_time_from, planned_end_time_to, completed_time_from, completed_time_to, search, sort_field, sort_direction, page, size}) {
      try {
        let sort = ""

        if (sort_field !== undefined && sort_direction !== undefined) {
          sort = sort_field + ',' + sort_direction
        }

        let filter = {
          department_id: department_id ? department_id : 0,
          engineer_id: engineer_id ? engineer_id : 0,
          team_id: team_id ? team_id : 0,
          object: object,
          status: status ? status : [],
          type: type ? type : [],
          planned_end_time_from: planned_end_time_from ? {seconds: convertDateString(planned_end_time_from + ' 00:00:00').getTime() / 1000} : null,
          planned_end_time_to: planned_end_time_to ? {seconds: convertDateString(planned_end_time_to + ' 23:59:59').getTime() / 1000} : null,
          completed_time_from: completed_time_from ? {seconds: convertDateString(completed_time_from + ' 00:00:00').getTime() / 1000} : null,
          completed_time_to: completed_time_to ? {seconds: convertDateString(completed_time_to + ' 23:59:59').getTime() / 1000} : null,
          search: search,
          sort: sort,
          page: page ? page : 1,
          size: size ? size : 50,
        }
        const response = await axios.post('{apiDomain}/crm/api/v1/tasks/list', filter);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchTaskById({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/tasks/' + id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchTaskReport({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/tasks/' + id + '/report');
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async updateTask({commit, dispatch}, task) {
      try {
        const response = await axios.put('{apiDomain}/crm/api/v1/tasks/' + task.id, task);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async changeTaskTeam({commit, dispatch}, task) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/tasks/' + task.id + '/team', task);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async changeTaskPlannedEndTime({commit, dispatch}, task) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/tasks/' + task.id + '/planned', task);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async createTask({commit, dispatch}, task) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/tasks', task);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async cancelTask({commit, dispatch}, task) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/tasks/' + task.task_id + '/cancel', task);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async closeTask({commit, dispatch}, task_id) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/tasks/' + task_id + '/close');
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async submitReport({commit, dispatch}, id) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/tasks/' + id + '/report/approve', {task_id: id});
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async declineReport({commit, dispatch}, id) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/tasks/' + id + '/report/decline', {task_id: id});
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async downloadReport({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/tasks/' + id + '/report/download', {
          headers: {Accept: `application/x-zip-compressed`},
          responseType: 'blob',
        });
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async uploadTasks({commit, dispatch}, file) {
      try {
        let formData = new FormData();
        formData.append('file', file);

        const response = await axios.post('{apiDomain}/crm/api/v1/tasks/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
  }
}
