import axios from "axios";

export default {
  actions: {
    async fetchActiveWorkingShifts({commit, dispatch}, departmentId) {
      try {
        let params = {
          department_id: departmentId,
          is_active: 1,
          sort: 'u.last_name,asc',
        }
        const response = await axios.get('{apiDomain}/crm/api/v1/working_shift', {params: params});
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchCompletedWorkingShifts({commit, dispatch}, {engineerId, dateFrom, dateTo}) {
      try {
        let params = {
          engineer_id: engineerId,
          date_from: dateFrom,
          date_to: dateTo,
          is_active: 0,
          approved: -1,
          sort: 'u.last_name,asc',
        }
        const response = await axios.get('{apiDomain}/crm/api/v1/working_shift', {params: params});
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchNonApprovedWorkingShifts({commit, dispatch}, engineerId) {
      try {
        let params = {
          'engineer_id': engineerId,
          'is_active': 0,
          'approved': 0,
        }
        const response = await axios.get('{apiDomain}/crm/api/v1/working_shift', {params: params});
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchWorkingShiftTracking({commit, dispatch}, {wsId, skipInterval, showRejected}) {
      try {
        let params = {
          'skip_minute_interval': skipInterval,
          'show_rejected': showRejected,
        }
        const response = await axios.get('{apiDomain}/crm/api/v1/working_shift/' + wsId + '/tracking', {params: params});
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async getTasksForWorkingShift({commit, dispatch}, wsId) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/working_shift/' + wsId + '/tasks');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async approveWorkingShift({commit, dispatch}, payload) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/working_shift/' + payload.id + '/approve', payload);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async reportWorkingShift({commit, dispatch}, payload) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/working_shift/report', {
          params: payload,
          headers: {Accept: `application/vnd.ms-excel`},
          responseType: 'text',
        });

        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
  }
}
