import axios from "axios";

export default {
  actions: {
    async fetchTeams({commit, dispatch}, {departmentId, page, size}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/teams', {
          params: {
            department_id: departmentId,
            page: page,
            size: size,
          }
        });
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchAllTeams({commit}) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/teams/all');
        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchTeamById({commit, dispatch}, id) {
      try {
        const response = await axios.get('{apiDomain}/crm/api/v1/teams/' + id);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async addTeam({commit, dispatch}, team) {
      try {
        const response = await axios.post('{apiDomain}/crm/api/v1/teams', team);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async releaseTeam({commit, dispatch}, teamId) {
      try {
        const response = await axios.delete('{apiDomain}/crm/api/v1/teams/'+ teamId);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async closeWorkingShift({commit, dispatch}, teamId) {
      try {
        const response = await axios.patch('{apiDomain}/crm/api/v1/teams/'+ teamId + '/close_ws');
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async fetchTeamMembers({commit, dispatch}, teamId) {
      try {
        let response = []

        if (teamId) {
          response = await axios.get('{apiDomain}/crm/api/v1/teams/' + teamId + '/members');
        } else {
          response = await axios.get('{apiDomain}/crm/api/v1/teams/members');
        }

        return response.data || []
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
    async updateTeamMembers({commit, dispatch}, {teamId, members}) {
      try {
        const response = await axios.put('{apiDomain}/crm/api/v1/teams/' + teamId + '/members', members);
        return response.data
      } catch (e) {
        commit('setError', e.response.data)
        throw e
      }
    },
  }
}
