import axios from 'axios';

export default {
  state: {
    user: null,
    taskFilter: JSON.parse(localStorage.getItem('taskFilter') || '{}'),
  },

  mutations: {
    user(state, value) {
      state.user = value;
    },
    taskFilter(state, value) {
      localStorage.setItem('taskFilter', JSON.stringify(value));
      state.taskFilter = value;
    },
  },

  computed: {
    user () {
      return this.user
    }
  },

  actions: {
    async initUserData({state, commit}) {
      try {
        const {data} = await axios.get('{apiDomain}/crm/api/v1/users/auth');
        commit('user', data);
      } catch (error) {
        const errorCode = get(error, 'response.status');
        if (errorCode !== 401) {
          commit('setError', error);
          throw error;
        }
        // dispatch('setPageError', error, { root: true });
      }

      return state.user
    },

    async getUid(state) {
      return state.state.user ? state.state.user.id : null
    },

    async isDispatcher(state) {
      return state.state.user.role === "dispatcher"
    },

    async isAdmin(state) {
      return state.state.user.role === "admin"
    },

    async getDepartmentId(state) {
      return state.state.user && state.state.user.department ? state.state.user.department.id : null
    },

    async setTaskFilter({store}, filter) {
      console.log(filter)
      localStorage.setItem('taskFilter', filter);
      store.commit('taskFilter', filter)
      return
    },
  },

  getters: {
    taskFilter: state => {
      return state.taskFilter;
    }
  },

  namespaced: true,
}
