export default function statusFilter(value) {
  const statusList = []
  statusList['waiting'] = 'Не назначена'
  statusList['not_started'] = 'Ожидание'
  statusList['in_progress'] = 'В работе'
  statusList['paused'] = 'Приостановлена'
  statusList['completed'] = 'Выполнена'
  statusList['verifying'] = 'Обработана'
  statusList['closed'] = 'Закрыта'

  return statusList[value]
}
