export default {
  install(Vue) {
    Vue.prototype.$inputThrottle = function(callback, limit) {
      let wait = false;

      if (!wait) {
        wait = true;
        setTimeout(function () {
          wait = false;
          callback.call();
        }, limit);
      }
    }
  }
}

