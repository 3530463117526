<template>
  <div class="grey darken-1 empty-layout">
    <router-view/>
  </div>
</template>

<script>
import messages from '@/utils/messages'

export default {
  computed: {
    error() {
      return this.$store.getters.error
    }
  },
  watch: {
    error(fbError) {
      let message = messages[fbError.code]
      if (!message) {
        console.error('Не найден код ошибки ' + fbError.code)
      }
      this.$error(message || 'Что-то пошло не так')
    }
  }
}
</script>
