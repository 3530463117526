<template>
  <ul class="sidenav app-sidenav" :class="{open: value}">
    <router-link
      v-for="link in links"
      :key="link.url"
      tag="li"
      active-class="active"
      :to="link.url"
      :exact="link.exact"
    >
      <a href="#" class="waves-effect waves-blue pointer">{{link.title}}</a>
    </router-link>
  </ul>
</template>

<script>
export default {
  props: ['value'],
  data: () => ({
    links: [
      { title: 'Главная', url: '/', exact: true },
      { title: 'Карта', url: '/maps' },
      { title: 'Рабочие смены', url: '/working_shifts' },
      { title: 'Заявки', url: '/tasks' },
      { title: 'Бригады', url: '/teams' },
      { title: 'Пользователи', url: '/users' },
      { title: 'Домашние локации', url: '/home_locations' },
      { title: 'Объекты', url: '/objects' },
      { title: 'Контрагенты', url: '/customers' },
      { title: 'Макро-регионы', url: '/macro_regions' },
      { title: 'Кластеры', url: '/clusters' },
      { title: 'Проекты', url: '/projects' },
      { title: 'Типы оборудования', url: '/equipment_types' },
      //{ title: 'Настройки', url: '/settings' },
    ]
  })
}
</script>



