import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Paginate from 'vuejs-paginate'
import VueMeta from 'vue-meta'
import VueLayers from 'vuelayers'
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import App from './App.vue'
import router from './router'
import store from './store'
import dateFilter from './filters/date.filter'
import statusFilter from './filters/status.filter'
import locationLinkFilter from './filters/location_link.filter'
import tooltipDirective from './directives/tooltip.directive'
import datePickerDirective from './directives/date_picker.directive'
import timePickerDirective from './directives/time_picker.directive'
import messagePlugin from './utils/message.plugin'
import titlePlugin from './utils/title.plugin'
import axiosPlugin from './utils/axios.plugin'
import throttlePlugin from './utils/throttle.plugin'
import Loader from './components/app/Loader'
import './registerServiceWorker'
import M from 'materialize-css/dist/js/materialize.min'
import 'vuelayers/lib/style.css'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.config.productionTip = false

if (process.env.VUE_APP_PRODUCTION_MODE === true) {
  Sentry.init({
    dsn: 'https://816907e8740842a6bf0baf10bf092f4e@o434085.ingest.sentry.io/5390471',
    integrations: [new VueIntegration({Vue, attachProps: true})],
    //logErrors: true,
  });
}

Vue.use(M)
Vue.use(messagePlugin)
Vue.use(titlePlugin)
Vue.use(throttlePlugin)
Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.use(VueLayers)
Vue.use(VueViewer)
Vue.filter('date', dateFilter)
Vue.filter('status', statusFilter)
Vue.filter('locationLink', locationLinkFilter)
Vue.directive('tooltip', tooltipDirective)
Vue.directive('datePicker', datePickerDirective)
Vue.directive('timePicker', timePickerDirective)
Vue.component('Loader', Loader)
Vue.component('Paginate', Paginate)

axiosPlugin(store, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
